<template>
	<div>
		<!-- 公共头部 -->
		<Header></Header>
		
		<div class="section1">
			<div class="item">
				<div class="title">我的积分</div>
				<font>{{userInfo.integral}}</font>
			</div>
			<router-link to="/integral/log" class="item">
				<div class="title">积分日志</div>
				<img class="icon" src="@/assets/images/icon69.png" />
			</router-link>
			<router-link to="/integral/list" class="item">
				<div class="title">积分兑换</div>
				<img class="icon" src="@/assets/images/icon69.png" />
			</router-link>
		</div>
		
		<div class="tip">当前汇率：1积分={{config.jf_money}}RMB</div>
		
		<div class="singlepage" v-html="singlepage"></div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '我的积分',
				userInfo: '',
				singlepage: ''
			}
		},
		computed: {
			uid() {
					return this.$store.state.uid;
			},
			config() {
				return this.$store.state.configInfo;
			}
		},
		activated() {
			this.getUserInfo();
		},
		methods: {
			// 获取积分记录
			async getIntegralLog(){
				var limit = 1;
				var page = 1;
				var uid = this.uid;
				var data = {
					limit, page, uid
				};
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/integral/integralLog', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('我的积分 积分记录：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.singlepage = res.data.jf_explain;
				} else {
					this.singlepage = '';
				}
			},
			// 根据用户id获取用户信息
			async getAjaxUserInfo() {
				var data = {
					uid: this.uid
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/user/userInfo', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('个人信息：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var userInfo = res.data;
					this.$store.commit("saveUserInfo", userInfo);
					this.userInfo = userInfo;
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
					this.userInfo = '';
				}
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return ;
					}
							wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getAjaxUserInfo();
					this.getIntegralLog();
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
		margin-top: 0.3rem;
	}
	.section1 .item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1rem;
		padding: 0 0.3rem;
		border-top: 0.02rem solid #e7e7e7;
	}
	.section1 .item:first-child{
		border-top: none;
	}
	.section1 .item .title{
		font-size: 0.28rem;
		color: #333333;
	}
	.section1 .item font{
		color: #ff6000;
	}
	.section1 .item .icon{
		width: 0.09rem;
		height: 0.17rem;
	}
	.tip{
		font-size: 0.28rem;
		color: #ff6000;
		margin: 0.3rem 0.3rem 0;
	}
	.singlepage{
		font-size: 0.28rem;
		margin: 0.3rem 0.3rem 0;
	}
</style>